body {
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
}

a:link, a:visited {
    color: #008bf6;
    text-decoration: none;
}
a:hover {
    color: #4daeff
}

